('use strict');
import { AppConfigurations } from '../config/app.config';
const apiConfig = new AppConfigurations();
export const APP_TITLE: string = apiConfig.config.title;
export const APP_ENVIRONMENT: string = apiConfig.config.environment;
export const SERVER_URL: string = apiConfig.config.apiUrl;
export const S3_BUCKET_URL: string = apiConfig.config.s3BucketUrl;
export const AWS_PUBLIC_BUCKET: string = apiConfig.config.awsS3PublicBucket;
export const AWS_PRIVATE_BUCKET: string = apiConfig.config.awsS3PrivateBucket;
export const AWS_S3_ACCESS_KEY: string = apiConfig.config.awsS3AccessKey;
export const AWS_S3_KEY_SECRET: string = apiConfig.config.awsS3SecretKey;
export const AWS_REGION: string = apiConfig.config.awsRegion;

export const LOGOUT: string = `/auth/logout`;
export const GET_USER_DETAILS: string = `users/me`;
export const USER_MANAGEMENT_STATUS_UPDATE: string = `users/{id}/status`;
export const GET_DEFAULTS_BASE_URL: string = `system/home`;

export const AUTH_REFRESH_TOKEN_BASE_URL: string = `/auth/refresh-token`;
export const USER_REFRESH_TOKEN_BASE_URL: string = `users/refresh-token`;

