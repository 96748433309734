export const environment = {
  production: false,
  apiUrl: import.meta.env?.API_URL || 'https://sid-stg-be.ideatolife.me',
  s3BucketUrl: import.meta.env?.S3_BUCKET_URL || 'https://sid-stg-public.s3.amazonaws.com/',
  awsS3PublicBucket: import.meta.env?.AWS_PUBLIC_BUCKET || 'sid-stg-public',
  awsS3PrivateBucket: import.meta.env?.AWS_PRIVATE_BUCKET,
  awsS3AccessKey: import.meta.env?.AWS_S3_ACCESS_KEY  || 'AKIAQHLG3CR3U2XYIIY7',
  awsS3SecretKey: import.meta.env?.AWS_S3_KEY_SECRET   || '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',
  awsRegion: import.meta.env?.AWS_REGION || 'us-east-1',
  demo: false,
  uat: false,
  development: true,
  local: false,
  environment: 'dev',
  env: {
    NG_APP_ENV: import.meta.env && import.meta.env['NG_APP_ENV'],
  },
};
